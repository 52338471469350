var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('section',{staticClass:"quem-somos"},[_vm._m(1),_c('banner-quem-somos')],1),_c('section',{staticClass:"covid-19"},[_vm._m(2),_c('banner-covid-19')],1),_c('section',{staticClass:"onde-estamos"},[_vm._m(3),_c('div',{staticClass:"container-base"},[_c('carrossel',{attrs:{"items":_vm.locaisOndeEstamos,"item-width":"320"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('router-link',{staticClass:"card-local",attrs:{"to":`/onde-estamos/${item.id}`}},[_c('cartao',{scopedSlots:_vm._u([{key:"imagem",fn:function(){return [_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.cms.getAssetUrl(item.foto.private_hash, {w: 600, h: 640, f: 'contain', q: 90})),expression:"cms.getAssetUrl(item.foto.private_hash, {w: 600, h: 640, f: 'contain', q: 90})"}],staticClass:"foto-local",attrs:{"data-srcset":`
                    ${_vm.cms.getAssetUrl(item.foto.private_hash, {w: 350, h: 640, f: 'contain', q: 90})} 350w,
                    ${_vm.cms.getAssetUrl(item.foto.private_hash, {w: 435, h: 640, f: 'contain', q: 90})} 435w,
                    ${_vm.cms.getAssetUrl(item.foto.private_hash, {w: 600, h: 640, f: 'contain', q: 90})} 600w,
                    ${_vm.cms.getAssetUrl(item.foto.private_hash, {w: 870, h: 640, f: 'contain', q: 90})} 870w,
                    ${_vm.cms.getAssetUrl(item.foto.private_hash, {w: 1200, h: 640, f: 'contain', q: 90})} 1200w,
                  `,"sizes":"\n                    (max-width: 890px) 600px,\n                    435px,\n                    (min-width: 1536px) 350px\n                  "}})]},proxy:true},(item.encerrado)?{key:"toast",fn:function(){return [_vm._v(" Projeto Encerrado ")]},proxy:true}:null,{key:"legenda",fn:function(){return [_vm._v(" "+_vm._s(item.cidade)),_c('br'),_c('b',[_vm._v(_vm._s(item.nome))])]},proxy:true}],null,true)})],1)]}}])}),_c('center',[_c('router-link',{staticClass:"button verde",attrs:{"to":"/onde-estamos"}},[_vm._v(" Veja todos ")])],1)],1)]),_vm._m(4),_c('section',{staticClass:"transparencia",attrs:{"id":"transparencia"}},[_c('h1',[_vm._v("Transparência")]),_c('div',{staticClass:"banner-transparencia"},[_c('img',{staticClass:"img-banner",attrs:{"src":require("@/assets/banner_transparencia.jpg"),"srcset":require("@/assets/banner_transparencia.jpg") + " 1x, " + require("@/assets/banner_transparencia@2x.jpg") + " 2x"}}),_c('div',{staticClass:"box-banner"},[_c('h2',[_vm._v("Ética e transparência fazem parte dos pilares da nossa marca. ")]),_c('p',[_vm._v("Como OSS, passamos por diversas auditorias de órgãos públicos e independentes, que atestam a nossa eficiência e responsabilidade.")]),_c('p',[_vm._v("Aqui você encontra publicações periódicas de nossos documentos e demais prestações de contas")]),_c('br'),_c('router-link',{staticClass:"button verde",attrs:{"to":"/transparencia"}},[_vm._v(" Acesse ")])],1),_c('div',{staticClass:"faixa-atras"})])]),_c('section',{staticClass:"parceiros",attrs:{"id":"parceiros"}},[_c('article',{staticClass:"container-base"},[_vm._m(5),_c('parceiros'),_c('p',[_c('center',[_c('router-link',{staticClass:"button verde",attrs:{"to":"/quem-somos","tag":"a"}},[_vm._v(" Quem Somos ")])],1)],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero"},[_c('h1',[_vm._v("NOSSA MISSÃO")]),_c('p',{staticClass:"subh"},[_vm._v(" Cuidar de quem cuida ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_vm._v(" Quem Somos "),_c('p',{staticClass:"subh"},[_vm._v(" Conheça a AHBB | Rede Santa Casa ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_vm._v(" Covid-19 "),_c('p',{staticClass:"subh"},[_vm._v(" Superamos juntos ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_vm._v(" Onde Estamos "),_c('p',{staticClass:"subh"},[_vm._v(" Conheça nossos projetos e serviços ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"areas-atuacao"},[_c('h1',[_vm._v("Áreas de Atuação")]),_c('picture',[_c('source',{attrs:{"media":"(min-width: 1041px)","srcset":require("@/assets/areas_atuacao.svg")}}),_c('img',{attrs:{"src":require("@/assets/areas_atuacao_mobile.svg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_vm._v(" Parceiros "),_c('p',[_vm._v("A AHBB/Rede Santa Casa se orgulha de formar, com dezenas de parceiros, times comprometidos com resultados na gestão de saúde de projetos em todo o país.")])])
}]

export { render, staticRenderFns }