<template>
  <div class="banner-covid-19" :style="{'--parallax-offset': `${(this.scroll - this.elOffset)}px`}">
    <div class="img-banner-wrapper">
      <img class="img-banner" src="@/assets/CardCovidHome.jpg" srcset="@/assets/CardCovidHome.jpg 1x, @/assets/CardCovidHome.jpg 2x">
    </div>
    <div class="box-banner">
      <h2>COVID-19: eficiência para superar&shy;mos juntos.</h2>
      <p>Centenas de profissionais da saúde dedicados fazem parte dos projetos de combate à COVID-19 implementados pela AHBB | Rede Santa Casa desde março de 2020.</p>
      <p>Conheça nosso trabalho, nossos parceiros e colaboradores, na luta contra a pior pandemia deste século.</p>
      <router-link to="/covid-19" class="button verde">
        Saiba Mais
      </router-link>
    </div>
    <div class="faixa-atras" />
  </div>
</template>

<style lang="scss" scoped>
  .banner-covid-19 {
    position: relative;
    height: 520px;
    width: 80%;
    max-width: 960px;
    margin: auto;

    transition: height .2s ease-in-out;

    --parallax: calc((-50vh - var(--parallax-offset))/ 20);

    .img-banner-wrapper {
      background: #dbdee3;
      border-radius: 32px;
      position: absolute;
      right: 0;
      width: 580px;
      max-width: 100%;
      top: 20px;
      bottom: 0;
      overflow: hidden;
      transition: width .2s ease-in-out, bottom .2s ease-in-out;

      .img-banner {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .box-banner {
      position: absolute;
      left: 0;
      max-width: 600px;
      bottom: 70px;
      min-height: 250px;

      z-index: 1;
      background: var(--ahbb-blue-gradient);
      padding: 40px;

      transform: translateY(var(--parallax));

      transition: bottom .2s ease-in-out, padding .2s ease-in-out, transform 0s linear;

      h2{
        font-size: 40px;
        color: white;
        font-weight: 200;
        margin: 0;
        top: 0;
      }

      p {
        color: white;
      }
    }

    .faixa-atras {
      background-color: #417A25;
      position: absolute;
      height: 120px;
      top: 0;
      right: 10%;
      width: 240px;
      max-width: 30%;
      z-index: -1;
    }

    @media (max-width: 1140px) {
      height: 580px;

      .img-banner-wrapper {
        bottom: 160px;
      }

      .box-banner {
        width: 94%;
        max-width: 480px;
        left: 3%;
        right: unset;
        top: unset;
        bottom: 0;
      }

    }

    @media (max-width: 880px) {
      .img-banner-wrapper {
        bottom: 200px;
      }
      
      .box-banner {
        padding: 32px;
      }
    }

    @media (max-width: 440px) {
      .img-banner-wrapper {
        bottom: 260px;
      }

      .box-banner {
        padding: 24px;
      }
    }

    @media (max-width: 360px) {
      .img-banner-wrapper {
        bottom: 260px;
      }
    }
  }
</style>

<script>
export default {
  data: () => ({
    scroll: 0,
    elOffset: 0,
    wHeight: 0,
    intersecting: false,
  }),
  methods: {
    wOnScroll(e) {
      this.scroll = window.scrollY
    },
  },
  watch: {
    intersecting(to, from) {
      if (to)
        window.addEventListener('scroll', this.wOnScroll)
      else
        window.removeEventListener('scroll', this.wOnScroll)
    },
  },
  mounted() {
    if ('IntersectionObserver' in window) {
      const obs = new IntersectionObserver((entries, obs) => {
        this.elOffset = this.$el.offsetTop + this.$el.offsetHeight / 2
        this.scroll = window.scrollY
        this.intersecting = entries[0].isIntersecting
      })
      obs.observe(this.$el)
    }
    else {
      this.elOffset = this.$el.offsetTop + this.$el.offsetHeight / 2
      this.scroll = window.scrollY

      this.intersecting = true
    }
  },
  beforeDestroy() {
    this.intersecting = false
  },
}
</script>
