<template>
  <figure
    :class="{loading, animate}"
    :style="{animationDelay: .1 + itemIndex * .08 + 's'}"
    class="card"
  >
    <div class="foto-card">
      <slot name="imagem">
        <div />
      </slot>
    </div>
    <div class="toast" v-if="!!$slots['toast']">
      <slot name="toast" />
    </div>
    <figcaption>
      <slot name="legenda" />
    </figcaption>
  </figure>
</template>

<style lang="scss" scoped>
figure.card {
  display: flex;
  height: 320px;
  margin: 0;

  flex-direction: column;

  background-color: #293B599b;

  overflow: hidden;
  
  &.animate{
    animation: fade-down .3s ease-out both;
  }

  .foto-card {
    flex: 1 0 0;
    min-height: 0;
    
    mix-blend-mode: multiply;

    transition: transform .2s ease-in-out, opacity .2s ease-in-out;
  }

  &:hover {
    cursor: pointer;

    .foto-card {
      transform: scale(1.1);
    }
  }

  .toast {
    padding: 6px 8px;
    z-index: 1;
    background: var(--ahbb-green);
    color: var(--ahbb-blue);
    text-align: center;
    text-transform: uppercase;
  }

  figcaption {
    width: 100%;
    padding: 20px;
    z-index: 1;

    flex: 0 0 auto;

    background: var(--ahbb-blue-gradient);
    color: white;

    font-size: 20px;

    p, span {
      color: white
    }
  }

  &.loading {
    &.animate {
      animation: fade-down .3s ease-out both, pulse 3s infinite alternate linear both;
    }

    figcaption * {
      background-color: #fff2;
      white-space: pre-wrap;
    }
  }
}
</style>

<script>
export default {
  props: {
    loading: Boolean,
    itemIndex: {
      type: Number,
      default: 0,
    },
    animate: Boolean,
  },
}
</script>
