<template>
  <div>
    <div v-for="gr in parceirosGrupos" :key="gr.id" class="grupo-parceiros">
      <img
        v-for="p in gr.parceiros"
        :key="p.id"
        :src="cms.getAssetUrl(p.logotipo.private_hash,{w: 200, h: 150, f: 'contain', q: 100})"
        class="parceiro"
        :alt="p.nome"
        :title="p.nome"
        loading="lazy"
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .grupo-parceiros {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    border-bottom: 3px solid var(--ahbb-green-light-3);
    padding-bottom: 20px;
    margin-top: 20px;

    &:last-child {
      border-bottom: none;
    }

    .parceiro {
      max-height: 70px;
      max-width: 104px;
      margin: 12px 4%;
      margin: 12px unquote('min(4%, 60px)');

      object-fit: contain;
    }

    &:nth-of-type(1) {
      .parceiro {
        max-height: 150px;
        max-width: 200px;
      }
    }
    
    &:nth-of-type(2) {
      .parceiro {
        max-height: 100px;
        max-width: 140px;
      }
    }

  }
</style>

<script>
import cms from '@/lib/cms.js'

export default {
  data: () => ({
    parceirosGrupos: [],
    cms,
  }),
  async created() {
    this.parceirosGrupos = (await cms.getItems('parceiros_grupos', { fields: '*.*,*.logotipo.private_hash' })).data
  },
}
</script>
