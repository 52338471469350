<template>
  <div class="carrossel-root" v-resize="onRootResize">
    <button
      class="carrossel-selector selector-left"
      @click.prevent="offset--;" :disabled="offset == 0"
      v-html="require('!svg-inline-loader!@/assets/chevron_left.svg')"
      aria-label="anterior"
    />
    <div :style="sliderStyle" class="carrossel-slider">
      <div class="carrossel">
        <div v-for="(item, index) of items" class="carrossel-item">
          <slot :item="item" />
        </div>
      </div>
    </div>
    <button
      class="carrossel-selector selector-right"
      @click.prevent="offset++" :disabled="offset == items.length - visibleCount"
      v-html="require('!svg-inline-loader!@/assets/chevron_right.svg')"
      aria-label="próximo"
    />
  </div>
</template>

<style lang="scss" scoped>
  .carrossel-root {
    position: relative;
    overflow: hidden;
    padding: 0 10%;

    &:after { /* fade lateral */
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: linear-gradient(to right, white, white 10%, #fff0 10%, #fff0 90%, white 90%, white);
      pointer-events: none;
    }
  }

  .carrossel {
    display: flex;
    align-items: center;
    margin: 5px 0;
    position: relative;
    justify-content: space-around;

    .carrossel-item {
      flex: 1 0 0;
      min-width: 0;
    }
  }

  .carrossel-slider {
    transition: transform .2s ease, width .2s ease;
  }

  .carrossel-selector {
    background: none;
    width: 10%;
    min-width: 32px;
    padding: 4px;

    color: var(--ahbb-blue);
    border: none;
    font-size: 54px;
    cursor: pointer;
    background-color: white;

    transition: opacity ease-in-out .15s;

    /deep/ svg {
      width: 90%;
      max-width: 24px;
      pointer-events: none;

      path {
        vector-effect: non-scaling-stroke;
        transition: stroke .2s ease;
      }
    }

    &:hover {
      /deep/ svg path {
        stroke: var(--ahbb-green);
      }
    }

    &:disabled {
      opacity: 0;
      cursor: default;
    }

    &.selector-left {
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      bottom: 0;
    }

    &.selector-right {
      position: absolute;
      z-index: 2;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
</style>

<script>
import Vue from 'vue'
import VueResizeObserver from 'vue-resize-observer'
Vue.use(VueResizeObserver)

export default {
  props: [
    'items',
    'itemWidth',
  ],
  data: () => ({
    width: 0,
    mOffset: 0,
  }),
  computed: {
    visibleCount() {
      return Math.min(this.items.length, Math.max(1, Math.floor(this.width * 0.8 / this.itemWidth)))
    },
    offset: {
      get: function() {
        return Math.max(0, Math.min(this.mOffset, this.items.length - this.visibleCount))
      },
      set: function(val) {
        this.mOffset = Math.max(0, Math.min(val, this.items.length - this.visibleCount))
      },
    },
    sliderStyle() {
      return {
        width: this.items.length / this.visibleCount * 100 + '%',
        transform: `translateX(-${this.offset / this.items.length * 100}%)`,
      }
    },
  },
  methods: {
    onRootResize({ width, height }) {
      this.width = width
    },
  },
  mounted() {
    this.width = this.$el.offsetWidth
  },
}
</script>
