<template>
  <div>
    <div class="hero">
      <h1>NOSSA MISSÃO</h1>
      <p class="subh">
        Cuidar de quem cuida
      </p>
    </div>
    <section class="quem-somos">
      <h1>
        Quem Somos
        <p class="subh">
          Conheça a AHBB | Rede Santa Casa
        </p>
      </h1>
      <banner-quem-somos />
    </section>
    <section class="covid-19">
      <h1>
        Covid-19
        <p class="subh">
          Superamos juntos
        </p>
      </h1>
      <banner-covid-19 />
    </section>
    <section class="onde-estamos">
      <h1>
        Onde Estamos
        <p class="subh">
          Conheça nossos projetos e serviços
        </p>
      </h1>
      <div class="container-base">
        <carrossel :items="locaisOndeEstamos" item-width="320">
          <template slot-scope="{ item }">
            <router-link
              :to="`/onde-estamos/${item.id}`"
              class="card-local"
            >
              <cartao>
                <template v-slot:imagem>
                  <img
                    class="foto-local"
                    v-lazy="cms.getAssetUrl(item.foto.private_hash, {w: 600, h: 640, f: 'contain', q: 90})"
                    :data-srcset="`
                      ${cms.getAssetUrl(item.foto.private_hash, {w: 350, h: 640, f: 'contain', q: 90})} 350w,
                      ${cms.getAssetUrl(item.foto.private_hash, {w: 435, h: 640, f: 'contain', q: 90})} 435w,
                      ${cms.getAssetUrl(item.foto.private_hash, {w: 600, h: 640, f: 'contain', q: 90})} 600w,
                      ${cms.getAssetUrl(item.foto.private_hash, {w: 870, h: 640, f: 'contain', q: 90})} 870w,
                      ${cms.getAssetUrl(item.foto.private_hash, {w: 1200, h: 640, f: 'contain', q: 90})} 1200w,
                    `"
                    sizes="
                      (max-width: 890px) 600px,
                      435px,
                      (min-width: 1536px) 350px
                    "
                  >
                </template>
                <template v-slot:toast v-if="item.encerrado">
                  Projeto Encerrado
                </template>
                <template v-slot:legenda>
                  {{ item.cidade }}<br>
                  <b>{{ item.nome }}</b>
                </template>
              </cartao>
            </router-link>
          </template>
        </carrossel>
        <center>
          <router-link to="/onde-estamos" class="button verde">
            Veja todos
          </router-link>
        </center>
      </div>
    </section>
    <section class="areas-atuacao">
      <h1>Áreas de Atuação</h1>
      <picture>
        <source media="(min-width: 1041px)" srcset="@/assets/areas_atuacao.svg">
        <img src="@/assets/areas_atuacao_mobile.svg">
      </picture>
    </section>
    <section id="transparencia" class="transparencia">
      <h1>Transparência</h1>
      <div class="banner-transparencia">
        <img class="img-banner" src="@/assets/banner_transparencia.jpg" srcset="@/assets/banner_transparencia.jpg 1x, @/assets/banner_transparencia@2x.jpg 2x">
        <div class="box-banner">
          <h2>Ética e transparência fazem parte dos pilares da nossa marca. </h2>
          <p>Como OSS, passamos por diversas auditorias de órgãos públicos e independentes, que atestam a nossa eficiência e responsabilidade.</p>
          <p>Aqui você encontra publicações periódicas de nossos documentos e demais prestações de contas</p>
          <br>
          <router-link to="/transparencia" class="button verde">
            Acesse
          </router-link>
        </div>
        <div class="faixa-atras" />
      </div>
    </section>
    <section id="parceiros" class="parceiros">
      <article class="container-base">
        <h1>
          Parceiros
          <p>A AHBB/Rede Santa Casa se orgulha de formar, com dezenas de parceiros, times comprometidos com resultados na gestão de saúde de projetos em todo o país.</p>
        </h1>
        <parceiros />
        <p>
          <center>
            <router-link
              to="/quem-somos"
              tag="a"
              class="button verde"
            >
              Quem Somos
            </router-link>
          </center>
        </p>
      </article>
    </section>
  </div>
</template>

<style lang="scss" scoped>
  h1 {
    text-align: center;
  }

  p.subh {
    text-align: center;
    margin-top: 8px;
  }

  section {
    margin: 56px 0;
  }

  .hero {
    min-height: 560px;
    padding-top: 200px;
    padding-bottom: 200px;

    background-image: url('../assets/bg_hero.jpg');
    background-image: image-set('../assets/bg_hero.jpg' 1x, '../assets/bg_hero@2x.jpg' 2x);
    
    p.subh {
      font-size: 40px;
      font-weight: 300;
    }
  }

  .banner-transparencia {
    position: relative;
    height: 520px;
    width: 80%;
    max-width: 1200px;
    margin: auto;

    img.img-banner {
      border-radius: 32px;
      position: absolute;
      left: 0;
      width: 700px;
      max-width: 100%;
      top: 30px;
    }

    .box-banner {
      position: absolute;
      right: 0;
      max-width: 660px;
      top: 10px;

      z-index: 1;
      background: var(--ahbb-blue-gradient);
      padding: 40px;

      h2 {
        font-size: 40px;
        color: white;
        font-weight: 300;
        line-height: 40px;

        &:first-child {
          margin-top: 8px;
        }
      }

      p {
        color: white;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .faixa-atras {
      background-color: #417A25;
      position: absolute;
      height: 80px;
      top: 10px;
      left: 20%;
      left: unquote('min(20%, 80px)');
      width: 180px;
      max-width: 32%;
      z-index: -1;
    }

    @media (max-width: 990px) {
      height: 800px;

      .box-banner {
        bottom: 0px;
        top: unset;
        left: 5%;
        right: 5%;
      }
    }

    @media(max-width: 550px) {
      height: 570px;

      .box-banner {
        h2 {
          font-size: 17px;
          line-height: 17px;
          font-weight: 500;
        }
  
        p:nth-child(2) {
          display: none;
        }
      }
    }
  }

  .onde-estamos {
    /deep/ .carrossel-item {
      margin: 0 8px;
    }

    .card-local {
      text-decoration: none;
      margin: 0 20px;
    }
  }

  .areas-atuacao {
    padding-top: 80px;
    padding-bottom: 80px;
    margin-bottom: 0;
    background-color: #293b590b;

    img {
      display: block;
      width: 80%;
      max-width: 920px;
      margin: 0 auto;

      @media (max-width: 1040px) {
        width: 90%;
        max-width: 480px;
      }
    }
  }

</style>

<script>
import cms from '@/lib/cms.js'
import Carrossel from '@/components/Carrossel.vue'
import Cartao from '@/components/Cartao.vue'
import Parceiros from '@/components/Parceiros.vue'
import BannerQuemSomos from '@/components/Home/BannerQuemSomos.vue'
import BannerCovid19 from '../components/Home/BannerCovid-19.vue'

export default {
  name: 'Home',
  data: () => ({
    parceirosGrupos: [],
    locaisOndeEstamos: [],
    cms,
  }),
  async created() {
    this.locaisOndeEstamos = (await cms.getItems('onde_estamos', { fields: 'id,nome,cidade,plano_de_trabalho,servico,encerrado,foto.private_hash' })).data
  },
  components: {
    Carrossel,
    Cartao,
    Parceiros,
    BannerQuemSomos,
    BannerCovid19,
  },
}
</script>
